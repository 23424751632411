import { createApp, defineAsyncComponent } from 'vue';
import { Component } from '@vue/runtime-core';

import { rootComponent } from '@digitalwerk/frontend-muster';
import { merge } from 'lodash';
import PageHeader from './components/PageHeader.vue';

const PageHeroTitleAnimation = defineAsyncComponent(() => import('./components/PageHeroTitleAnimation.vue'));
const SimpleCollapse = defineAsyncComponent(() => import('./components/SimpleCollapse.vue'));
const PageHeroSlider = defineAsyncComponent(() => import('./components/PageHeroSlider.vue'));
const LogowallSlider = defineAsyncComponent(() => import('./components/LogowallSlider.vue'));
const ContentSlider = defineAsyncComponent(() => import('./components/ContentSlider.vue'));
const QuoteSlider = defineAsyncComponent(() => import('./components/QuoteSlider.vue'));
const ProductSlider = defineAsyncComponent(() => import('./components/ProductSlider.vue'));
const LatestProductsSlider = defineAsyncComponent(() => import('./components/LatestProductsSlider.vue'));
const NewsList = defineAsyncComponent(() => import('./../../../../dw_page_types/Resources/Private/Vue/News/List.vue'));
const BlogList = defineAsyncComponent(() => import('./../../../../dw_page_types/Resources/Private/Vue/Blog/List.vue'));
const ApartmentList = defineAsyncComponent(() => import('./../../../../dw_page_types/Resources/Private/Vue/Apartment/List.vue'));
const VillaList = defineAsyncComponent(() => import('./../../../../dw_page_types/Resources/Private/Vue/Villa/List.vue'));
// const AjaxForm = defineAsyncComponent(() => import('./components/AjaxForm.vue'));

const projectRootComponent: Component = {
  components: {
    'page-header': PageHeader,
    'page-hero-title-animation': PageHeroTitleAnimation,
    'simple-collapse': SimpleCollapse,
    'page-hero-slider': PageHeroSlider,
    'logowall-slider': LogowallSlider,
    'content-slider': ContentSlider,
    'quote-slider': QuoteSlider,
    'product-slider': ProductSlider,
    'latest-products-slider': LatestProductsSlider,
    'news-list': NewsList,
    'blog-list': BlogList,
    'apartment-list': ApartmentList,
    'villa-list': VillaList,
    // 'ajax-form': AjaxForm
  },
  methods: {
    mountedHook() {
        this.checkBanner();
        this.apartmentStickyButtons();
        this.sectionMenuScrollObserver();
        this.productTeaserAutoHeight();
        window.addEventListener('resize', this.productTeaserAutoHeight);
        this.hidePageHeroBreadcrumb();
    },
    checkBanner() {
      const banner = document.querySelector('.header .ce-info-banner');
      if (banner) {
        document.body.classList.add('banner--showed');
      }
    },
    apartmentStickyButtons() {
        window.addEventListener('scroll', (event) => {
            const scrollY = Math.round(window.scrollY);
            const stickyTop = 220;
            const header = document.querySelector('.apartment-detail-header');

            if (header) {
                if (scrollY >= stickyTop) {
                    header.classList.add('apartment-detail-header--sticky');
                } else {
                    header.classList.remove('apartment-detail-header--sticky');
                }
            }
        });
    },
    openSubNav(event: any) {
      const navItem = event.target.parentElement;
      if (navItem.classList.contains('sub')) {
        event.preventDefault();
        const subNav = navItem.querySelector('.main-sub-nav--mobile');
        subNav.classList.toggle('js-opened');
        const mobileNav = document.querySelector<HTMLElement>('.mobile-nav');
        if (mobileNav) {
          mobileNav.classList.toggle('js-opened');
        }
      }
    },
    openAnchorMenuItem(event: any) {
        const clientWidth = document.documentElement.clientWidth;
        if (clientWidth <= 1366) {
            const body = document.querySelector('body');
            const header = document.querySelector('.header');
            if (body && header) {
                body.classList.remove("mobile-nav-opened");
                header.classList.add("header--hidden");
            }
        }
    },
    closeSubNav() {
      const allSubNavs = document.querySelectorAll('.main-sub-nav--mobile');
      if (allSubNavs) {
        allSubNavs.forEach((subNav) => {
          subNav.classList.remove('js-opened');
        });
      }
      const mobileNav = document.querySelector<HTMLElement>('.mobile-nav');
      if (mobileNav) {
        mobileNav.classList.remove('js-opened');
      }
    },
    closeBothNav() {
      const allSubNavs = document.querySelectorAll('.main-sub-nav--mobile');
      if (allSubNavs) {
        allSubNavs.forEach((subNav) => {
          subNav.classList.remove('js-opened');
        });
      }
      const mobileNav = document.querySelector<HTMLElement>('.mobile-nav');
      if (mobileNav) {
        mobileNav.classList.remove('js-opened');
      }
      const closeBtn = document.getElementById('mobile-nav-close-btn');
      if (closeBtn) {
        closeBtn.click();
      }
    },
    sectionMenuActive(event: any) {
        const navItem = event.target;
        var sectionMenu = document.querySelector(".apartment-detail-section-menu");
        if (sectionMenu) {
            var sectionItems = sectionMenu.getElementsByTagName('a');
            for (var i = 0; i < sectionItems.length; i += 1) {
                sectionItems[i].classList.remove("active");
            }
        }
        navItem.classList.add("active");

        // scroll into section with offset
        const hrefUid = navItem.getAttribute('data-uid');
        const sections = document.querySelectorAll('.ge-section');
        if (sections) {
            sections.forEach((section) => {
                const uid = section.getAttribute('data-uid');

                if (hrefUid == uid) {
                    setTimeout(function() {
                        var windowWidth = window.innerWidth;
                        if (windowWidth < 992) {
                            if (window.pageYOffset == 0) {
                                var offset = 525;
                            } else {
                                var offset = 260;
                            }
                        } else {
                            if (window.pageYOffset == 0) {
                                var offset = 425;
                            } else {
                                var offset = 180;
                            }
                        }

                        const elementPosition = section.getBoundingClientRect().top;
                        const offsetPosition = elementPosition + window.pageYOffset - offset;

                        window.scrollTo({
                            top: offsetPosition,
                            behavior: 'smooth'
                        });
                    }, 400);
                }
            });
        }
    },
    apartmentScrollWithOffset(event: any) {
        const clickItem = event.target;
        const hrefUid = clickItem.getAttribute('data-uid');
        if (hrefUid.includes('http') || hrefUid.includes('https')) {
            if (hrefUid.includes(' _blank')) {
                const hrefUidBlank = hrefUid.split(' _blank').shift();
                window.open(hrefUidBlank, '_blank');
            } else {
                window.location.href = hrefUid;
            }
        } else {
            const hrefUidClean = hrefUid.split('#').pop();

            setTimeout(function() {
                var windowWidth = window.innerWidth;
                if (windowWidth < 992) {
                    if (window.pageYOffset == 0) {
                        var offset = 525;
                    } else {
                        var offset = 260;
                    }
                } else {
                    if (window.pageYOffset == 0) {
                        var offset = 380;
                    } else {
                        var offset = 200;
                    }
                }

                const moveToElement = document.getElementById("c" + hrefUidClean);
                if (moveToElement) {
                    const elementPosition = moveToElement.getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - offset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
            }, 400);
        }
    },
    rainerTeaserOpen(event: any) {
      const teaserItem = event.target.closest('.ce-inverse-teasers-item');
      const teaserVideo = teaserItem.querySelector('.ce-inverse-teasers-item-videofile');

      var teasers = document.querySelector(".ce-inverse-teasers");
      if (teasers) {
          var teaserItems = teasers.querySelectorAll('.ce-inverse-teasers-item');
          for (var i = 0; i < teaserItems.length; i += 1) {
              teaserItems[i].classList.remove("ce-inverse-teasers-item--active");
          }
      }

      teaserItem.classList.add("ce-inverse-teasers-item--active");
      teaserItem.scrollIntoView({behavior: "smooth", block: "center"});

      const videos = document.querySelectorAll('.ce-inverse-teasers-item-videofile');
      if (videos) {
          videos.forEach((video) => {
              // @ts-ignore
              video.pause();
          });
      }
      if (teaserVideo) {
            // @ts-ignore
            teaserVideo.play();
      }
    },
    rainerTeaserClose(event: any) {
      const teaserItem = event.target.closest(".ce-inverse-teasers-item");

      var teasers = document.querySelector(".ce-inverse-teasers");
      if (teasers) {
          var teaserItems = teasers.querySelectorAll('.ce-inverse-teasers-item');
          for (var i = 0; i < teaserItems.length; i += 1) {
              teaserItems[i].classList.remove("ce-inverse-teasers-item--active");
          }
      }
      teaserItem.scrollIntoView({behavior: "smooth", block: "center"});
        const videos = document.querySelectorAll('.ce-inverse-teasers-item-videofile');
        if (videos) {
            videos.forEach((video) => {
                // @ts-ignore
                video.pause();
            });
        }
    },
    sectionMenuScrollObserver() {
        const root = document.querySelector('#main');
        if (root) {
            const sections = root.querySelectorAll('.ge-section');
            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const visibleSection = entry.target;
                        const sectionId = visibleSection.getAttribute('id');
                        const sectionMenuLinks = document.querySelectorAll('.apartment-detail-section-menu li > a');
                        if (sectionMenuLinks) {
                            sectionMenuLinks.forEach((link) => {
                                link.classList.remove("active");

                                const linkHref = link.getAttribute('href');
                                if (linkHref) {
                                    const linkHrefClear = linkHref.replace('#','');

                                    if (sectionId == linkHrefClear) {
                                        link.classList.add("active");
                                    }
                                }
                            });
                        }
                    }
                });
            }, {
                rootMargin: '-50% 0%'
            });

            Array.prototype.forEach.call(sections, item => {
                observer.observe(item);
            });
        }
    },
    productTeaserAutoHeight() {
        const bowserWidth = window.innerWidth;
        if (bowserWidth >= 768) {
            const productTeasersTitles = document.querySelectorAll<HTMLElement>('.content-element--none .product-teaser .product-teaser-content-title-col');
            if (productTeasersTitles) {
                // Get height of all product teaser titles
                let productTitleHeights = new Array;
                productTeasersTitles.forEach((teaser) => {
                    let teaserTitleHeight = teaser.offsetHeight;
                    productTitleHeights.push(teaserTitleHeight);
                });

                // Get height of the biggest product title
                let minHeight = Math.max(...productTitleHeights);

                // Apply min height for all product teaser titles
                const titles = document.querySelectorAll<HTMLElement>('.content-element--none .product-teaser .product-teaser-content-title-col');
                if (titles) {
                    titles.forEach((title) => {
                        title.style.minHeight = minHeight + "px";
                    });
                }
            }

            const productTeasersTexts = document.querySelectorAll<HTMLElement>('.content-element--none .product-teaser .product-infoPoint--text');
            if (productTeasersTexts) {
                // Get height of all product teaser text blocks
                let productTextHeights = new Array;
                productTeasersTexts.forEach((teaser) => {
                    let teaserTextHeight = teaser.offsetHeight;
                    productTextHeights.push(teaserTextHeight);
                });

                // Get height of the biggest product text block
                let minHeightText = Math.max(...productTextHeights);

                // Apply min height for all product teaser text blocks
                const titlesTexts = document.querySelectorAll<HTMLElement>('.content-element--none .product-teaser .product-infoPoint--text');
                if (titlesTexts) {
                    titlesTexts.forEach((text) => {
                        text.style.minHeight = minHeightText + "px";
                    });
                }
            }
        }
    },
    playMedia(event: any) {
        let currentElement = event.target.closest('.ce-media-item-wrapper');
        let mediaElement = currentElement.querySelector('.ce-media-item--video');
        if (mediaElement) {
            let mediaPoster = currentElement.querySelector('.ce-media-poster');
            if (mediaPoster) {
                mediaPoster.style.display = "none";
            }

            // Play YouTube & Vimeo
            let videoSource = mediaElement.getAttribute('src');
            if (videoSource) {
                let videoSourceAutoplay = videoSource + "&autoplay=1";
                mediaElement.setAttribute('src', videoSourceAutoplay);
            } else {
                // Play local video
                mediaElement.play();
            }
        }
    },
    hidePageHeroBreadcrumb() {
        const contentElements = document.querySelectorAll<HTMLElement>('#main .content-element');
        if (contentElements) {
            let i = 0;
            contentElements.forEach((element) => {
                i++;
                if (i === 1) {
                    if (element.classList.contains('ce-page-hero') || element.classList.contains('ce-page-hero-slider')) {
                        let breadcrumb = document.querySelector<HTMLElement>('.breadcrumb');
                        if (breadcrumb) {
                            breadcrumb.style.display = "none";
                        }
                    }
                }
            });
        }
    },
  }
};

const app = createApp(merge(rootComponent, projectRootComponent));
app.component((rootComponent as any).components.PageHeader);
app.mount('#page');
