
import { defineComponent, ref } from 'vue';

const SCROLL_DELTA = 50;

export default defineComponent({
    data() {
        return {
            isSearchOpen: false
        }
    },
    setup() {
        let isHidden = ref(false);
        let lastScrollPosition = window.pageYOffset;

        window.addEventListener('scroll', () => {
            if (window.pageYOffset >= 0) {
                if (Math.abs(window.pageYOffset - lastScrollPosition) >= SCROLL_DELTA) {
                    isHidden.value = window.pageYOffset >= lastScrollPosition;
                    lastScrollPosition = window.pageYOffset;
                }
            }
        });

        return {
            isHidden
        };
    },
    methods: {
        toggleMobileNav() {
            let mobileNav = document.querySelector('.mobile-nav');

            if (mobileNav) {
                document.body.classList.toggle('mobile-nav-opened');
                if (document.body.classList.contains('mobile-nav-opened')) {
                    // opening mobile nav
                    const activeNavItems: Array<HTMLElement> = [];
                    Array.from<HTMLAnchorElement>(mobileNav.querySelectorAll('a.active')).forEach((link) => {
                        const activeNavItem = link.parentElement;
                        if (activeNavItem && activeNavItem.classList.contains('sub')) {
                            activeNavItems.push(activeNavItem);
                        }
                    });
                    if (activeNavItems.length > 0) {
                        const mobileNav = document.querySelector<HTMLElement>('.mobile-nav');
                        if (mobileNav) {
                            mobileNav.classList.toggle('js-opened');
                        }
                    }
                    activeNavItems.forEach((item) => {
                        item.querySelector('nav.main-sub-nav--mobile')?.classList.add('js-opened');
                    });
                } else {
                    // closing mobile nav
                    Array.from<HTMLAnchorElement>(mobileNav.querySelectorAll('.js-opened')).forEach((item) => {
                        item.classList.remove('js-opened');
                    });
                }
            }
        },
    }
})
